import * as React from "react"
import "../styles/news.css"
const ImageCaptionComponent = ({ imageURL, link, name }) => {
    return (
        <a href={link} title={name}>
            <div className="border-8 border-white partner-item flex items-center">
                <img loading="lazy" src={imageURL} alt={name}></img>
            </div>
        </a>
    )
}

export default ImageCaptionComponent;
import * as React from "react"
import ImageCaptionComponent from "../components/image-caption"
import TitleAltComponent from "../components/title-alt"
import LayoutSENR from "../components/layout/layout-senr"
import { useTranslation } from "gatsby-plugin-react-i18next";
import LinkButton from "../components/elements/LinkButton";
import { graphql } from 'gatsby'
import SEOComponent from "../components/seo";


const PaternairesPage = ({ data }) => {
  const siteContent = data.siteContent
  const { t } = useTranslation();
  return (
    <main>
      <LayoutSENR siteContent={siteContent}>
        <SEOComponent title={t("menu_partners")} />
        <TitleAltComponent title={t("menu_partners")} subtitle={t("become_partner") + " !"} description={siteContent.partner.description}></TitleAltComponent>
        <div className="section px-4 flex flex-col mb-10 gap-7">
          <LinkButton to="/contact" className="mb-8 self-start justify-self-start">{t("become_partner")}</LinkButton>
          <span className="text-xl"><strong>{t("partners_support")}</strong></span>
        </div>
        <div className="section grid grid-cols-articles justify-items-center gap-20">
          {data.partners.nodes.map(partner => <ImageCaptionComponent imageURL={partner.imageUrl} link={partner.linkPartner} name={partner.name}></ImageCaptionComponent>)}
        </div>
      </LayoutSENR>
    </main>
  )
}
export default PaternairesPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter:{language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
          
        }
      }
    }
    partners: allPartner {
      nodes {
        imageUrl
        name
        linkPartner
      }
    }
    siteContent(language: {eq: $language}) {
          editionYear
          programYear
          linkPass
          mediaKit
          edition {
            description
          }
          partner {
            description
          }
          language
          cookieURL
        personalDataURL
        banner
    }
    editionArticles: allArticle(sort: {fields: datePublished},limit: 40,filter: {typeArticle: {eq: ressources},language: {eq: $language}}) {
      nodes {
        title
        slug
        shortDescription
        thumbnailImage
        categories
      }
      
    }
  }
`

import * as React from "react"
import '../styles/title.css'
import parse from "../utils/html-parser.options"

const TitleAltComponent = ({ title, subtitle, description }) => {
    return (
        <section className="flex flex-col container px-4 mx-auto items-start my-20">
            <h1 className="text-7xl font-black m-0 uppercase lg:text-4xl">{title}</h1>
            <div className="text-2xl mt-2">
                {description ? parse(description) : ""}
            </div>

        </section>
    )
}

export default TitleAltComponent;